import * as TWEEN from '@tweenjs/tween.js';
class Animator {
    constructor() {
        this.animations = [];
    }

    AnimateDown(object, duration) {
        if (this.animations.some(animation => animation.object === object)) return;

        const downTween = new TWEEN.Tween(object.position)
            .to({ y: object.position.y - .06 }, duration)
            .easing(TWEEN.Easing.Circular.Out)
            .onComplete(() => {
                this.AnimateUp(object, duration);
            });

        this.animations.push({ object, tween: downTween, isUp: false });
        downTween.start();
    }

    AnimateUp(object, duration) {
        const upTween = new TWEEN.Tween(object.position)
            .to({ y: object.position.y + .06 }, duration * 2)
            .easing(TWEEN.Easing.Sinusoidal.Out)
            .onComplete(() => {
                const index = this.animations.findIndex(animation => animation.object === object);
                if (index !== -1) {
                    this.animations[index].completed = true;
                }
            });
        const index = this.animations.findIndex(animation => animation.object === object);
        if (index !== -1) {
            this.animations[index].tween = upTween;
            this.animations[index].isUp = true;
        }
        upTween.start();
    }

    Update(time) {
        for (let i = this.animations.length - 1; i >= 0; i--) {
            const animation = this.animations[i];
            animation.tween.update(time);
            if (animation.completed && animation.isUp) {
                this.animations.splice(i, 1);
            }
        }
    }
}

export default Animator;