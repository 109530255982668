export function rand(min, max) {
    if (max === undefined) {
        max = min;
        min = 0;
    }
    return min + (max - min) * Math.random();
}

export function randomColor() {
    return `hsl(${rand(360) | 0}, ${rand(50, 100) | 0}%, 70%)`;
}
