import * as THREE from 'three';
//import { sizes } from './utils/sizes';
//import PickHelper from './utils/PickHelper';
import InputHelper from './utils/InputHelper';
import Animator from './utils/Animator';
import { Setup, ToggleRecord } from './scenes/PianoScene';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import * as WebAudio from 'webaudio';

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
};


//console.log(DogSample);
const anim = new Animator();
var audioContext = undefined;

export function InitApp() {
    const scene = Setup();
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000);
    camera.position.set(0, 1, 2);
    camera.lookAt(scene.position);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(sizes.width, sizes.height);
    //renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    
    document.body.appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enablePan = false;
    //const pickHelper = new PickHelper(renderer.domElement, AnimateObject);
    const input = new InputHelper(renderer.domElement, InteractWithObject, scene, camera);
    const clock = new THREE.Clock();

    
    const animate = () => {
        const elapsedTime = clock.getElapsedTime();
        anim.Update();
        renderer.render(scene, camera);
        controls.update();
        //pickHelper.pick(scene, camera, elapsedTime);
        window.requestAnimationFrame(animate);
    };

    animate();
}
function InteractWithObject(object) {
    if (object.name === 'rec') {
        console.log('start rec');
        ToggleRecord();
    }

    if (parseInt(object.name) < 100) {
        //PlaySampleAtTime(DogSample, 0);
        //var sample = LoadSample("dog-bark-c.wav");
        //sample.then((buffer) => {
        //    PlaySampleAtTime(buffer, 0);
        //});
        var p = parseInt(object.name) - 13;

        LoadAndPlay("dog-bark-c.wav", 0, p);

    }
    anim.AnimateDown(object, 100);
}



async function LoadSample(url) {
    if (!audioContext) {
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
    }
    try {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        return audioBuffer;
    } catch (error) {
        console.error('Error loading audio sample:', error);
        throw error;
    }
}

function PlaySampleAtTime(buffer, delayInSeconds, pitchShift = 0) {
    if (!audioContext) {
        console.error('AudioContext not initialized');
        return;
    }
    const source = audioContext.createBufferSource();
    source.buffer = buffer;
    source.connect(audioContext.destination);

    // Apply pitch shift
    // The detune value is in cents (100 cents = 1 semitone)
    source.detune.value = pitchShift * 100;

    const startTime = audioContext.currentTime + delayInSeconds;
    source.start(startTime);

    return source; // Return the source node for potential future control
}

// Usage example
async function LoadAndPlay(url, delay, pitch) {
    try {
        const sample = await LoadSample(url);
        PlaySampleAtTime(sample, delay, pitch);
    } catch (error) {
        console.error('Failed to load or play sample:', error);
    }
}
