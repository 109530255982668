import * as THREE from 'three';
import { randomColor } from '../utils/random';
//colors
const _black = new THREE.Color(.05, .05, .05);
const _gray = new THREE.Color(0xf5f5f5);
const _red = new THREE.Color(0xa50000);

//constants
const _whiteKeyWidth = 0.15;
const _blackKeyWidth = 0.075;
const _whiteKeyHeight = 0.5;
const _blackKeyHeight = 0.35;
const _keyDepth = 0.08;
const _numKeys = 24;

//materials
const _blackMaterial = new THREE.MeshStandardMaterial({ color: _black });
const _recMaterial = new THREE.MeshPhongMaterial({ color: _red });
const _baseMaterial = new THREE.MeshPhongMaterial({ color: _gray });
_recMaterial.emissive = new THREE.Color(_red);
_recMaterial.emissiveIntensity = 0;

//geometry
const _geometryWhite = new THREE.BoxGeometry(_whiteKeyWidth, _keyDepth, _whiteKeyHeight);
const _geometryBlack = new THREE.BoxGeometry(_blackKeyWidth, _keyDepth, _blackKeyHeight);
const _geometryCylinder = new THREE.CylinderGeometry(.05, .05, .1, 8);

//rec light
const _cylinderLight = new THREE.PointLight(0xa50000, 0, 50);

function CreatePiano(scene) {
    const piano = new THREE.Group();

    //rec btn
    const cylinder = new THREE.Mesh(_geometryCylinder, _recMaterial);
    cylinder.position.add(new THREE.Vector3(0, 0, -.4))
    _cylinderLight.position.add(new THREE.Vector3(0, .1, -.4));
    cylinder.name = 'rec';
    piano.add(cylinder);
    piano.add(_cylinderLight);

    //keys
    let whiteCount = 0;
    for (let i = 0; i < _numKeys; ++i) {
        const isBlack = IsBlackKey(i);
        const geometry = isBlack ? _geometryBlack : _geometryWhite;
        const material = isBlack ? _blackMaterial : new THREE.MeshStandardMaterial({ color: randomColor() });
        const cube = new THREE.Mesh(geometry, material);
        cube.castShadow = true;

        if (isBlack) {


            cube.position.set(
                whiteCount * _whiteKeyWidth - (_whiteKeyWidth * 0.5),
                _keyDepth * .8,
                (_whiteKeyHeight - _blackKeyHeight) * -0.55
            );
        } else {

            cube.position.set(
                whiteCount * _whiteKeyWidth,
                0,
                0
            );

            whiteCount++;
        }
        cube.name = i.toString();
        piano.add(cube);
    }

    //base
    const _geometryBase = new THREE.BoxGeometry(_whiteKeyWidth * (1 + whiteCount), _keyDepth * 1.5, _whiteKeyHeight * 1.6);
    const baseCube = new THREE.Mesh(_geometryBase, _baseMaterial);
    baseCube.position.add(new THREE.Vector3(_whiteKeyWidth * (-1 + whiteCount) * 0.5, -_keyDepth * 1.1, -.1));
    baseCube.name = 'base';
    piano.add(baseCube);

    piano.position.add(new THREE.Vector3(-.5 * (-1 + whiteCount) * _whiteKeyWidth, 0, 0));
    scene.add(piano);
}

export function Setup() {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x0096FF);
    const ambientLight = new THREE.AmbientLight(0xffffff, .8);
    scene.add(ambientLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.castShadow = true;
    directionalLight.position.set(0, .52, 2);
    scene.add(directionalLight);

    CreatePiano(scene);

    return scene;
}

export function ToggleRecord() {
    _cylinderLight.intensity = _cylinderLight.intensity === 0 ? 1 : 0;
    _recMaterial.emissiveIntensity = _recMaterial.emissiveIntensity === 0 ? 1 : 0;
}

function IsBlackKey(index) {
    return [1, 3, 6, 8, 10].includes(index % 12);
}
