import * as THREE from 'three';
import { GUI } from 'dat.gui'

class InputHelper {
    constructor(canvas, objectClickCallback, scene, camera) {
        this.raycaster = new THREE.Raycaster();
        this.pickedObject = null;
        this.pickedObjectSavedColor = 0;
        this.pickPosition = { x: -100000, y: -100000 };
        this.canvas = canvas;
        this.scene = scene;
        this.camera = camera;
        this.mousePosition = { x: -100000, y: -100000 };

        this.canvas.addEventListener('mousedown', (event) => this.onClick(event, this.canvas));

        this.onObjectClick = objectClickCallback;

        this.gui = new GUI();
        //this.mousePosition = { x: 0, y: 0 }; // Object to hold mouse coordinates
        this.gui.add(this.pickPosition, 'x').name('Pick X').listen();
        this.gui.add(this.pickPosition, 'y').name('Pick Y').listen();

        this.gui.add(this.mousePosition, 'x').name('Mouse X').listen();
        this.gui.add(this.mousePosition, 'y').name('Mouse Y').listen();

    }

    onClick(event, canvas) {
        const rect = this.canvas.getBoundingClientRect();
        //alert(rect.width + rect.height);
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        this.mousePosition.x = x;
        this.mousePosition.y = y;

        this.setPickPosition(x, y, this.canvas.width, this.canvas.height);

        this.raycaster.setFromCamera(this.pickPosition, this.camera);

        const intersectedObjects = this.raycaster.intersectObjects(this.scene.children);

        if (intersectedObjects.length) {
            if (intersectedObjects[0].object.name === 'base') return;
            this.pickedObject = intersectedObjects[0].object;
        }

        if (this.pickedObject) {
            console.log('clicked on object ' + this.pickedObject.name);

            if (this.onObjectClick) {
                this.onObjectClick(this.pickedObject);
            }

        } else {
            //console.log('clicked on empty space');
        }

        this.pickedObject = undefined;
    }

    setPickPosition(x, y, canvasWidth, canvasHeight) {
        this.pickPosition.x = (x / canvasWidth) * 2 - 1;
        this.pickPosition.y = (y / canvasHeight) * -2 + 1;
    }

    clearPickPosition() {
        this.pickPosition.x = -100000;
        this.pickPosition.y = -100000;
    }
}

export default InputHelper;
